import React from 'react'

function Footer() {

  return (
    <footer>
      <p>Penelope Jungreis &copy; 2021</p>
    </footer>
  )
}

export default Footer